import React from 'react';
import { SiFacebook, SiTwitter, SiYoutube, SiLinkedin, SiWhatsapp, SiInstagram, SiTelegram } from "react-icons/si";

function Sociallinks(props) {
    return (
        <>

            {props.linktype === "facebook" ? <a href={props.link} target="_blank" rel="noreferrer"><SiFacebook size={props.size} color={props.color} /></a>
                : props.linktype === 'twitter' ? < a href={props.link} target="_blank" rel="noreferrer"><SiTwitter size={props.size} color={props.color} /></a>
                    : props.linktype === 'linkedin' ? < a href={props.link} target="_blank" rel="noreferrer"><SiLinkedin size={props.size} color={props.color} /></a>
                        : props.linktype === 'youtube' ? < a href={props.link} target="_blank" rel="noreferrer"><SiYoutube size={props.size} color={props.color} /></a>
                            : props.linktype === 'whatsapp' ? < a href={props.link} target="_blank" rel="noreferrer"><SiWhatsapp size={props.size} color={props.color} /></a>
                                : props.linktype === 'instagram' ? < a href={props.link} target="_blank" rel="noreferrer"><SiInstagram size={props.size} color={props.color} /></a>
                                    : props.linktype === 'telegram' ? < a href={props.link} target="_blank" rel="noreferrer"><SiTelegram size={props.size} color={props.color} /></a> : <></>}

        </>
    )
}

export default Sociallinks
