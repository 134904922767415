export const testimonialsdata=[
    {
        "id":"001",
        "name":"N.Rajendran",
        "company":"Raja Hardwares & Electricals",
        "imageUrl":"/assets/testimonials/002.jpg",
        "testimonial":"I would recommend OPTS for any small and medium scale businesses. They helped us solve all our complex financial problems like tax planning, business guidance, accounts and GST filings. One Point tax solution is our real end-to-end partner for all our financial needs."
    },
    {
        "id":"002",
        "name":"R.Gopinath",
        "company":"R. Gopinath Suppliers",
        "imageUrl":"/assets/testimonials/001.jpg",
        "testimonial":"One Point Tax Solutions is our partner, they’re more than a tax and accounting consultancy. Right from helping us maintain proper accounts, to filing GST returns to improve our profits, OPTS handles everything for us."
    },
    {
        "id":"003",
        "name":"Balaji G",
        "company":"Indie Spirit Technologies",
        "imageUrl":"/assets/testimonials/003.jpg",
        "testimonial":"OPTS just removes all our tax filing hassle from our organisation, so that we can concentrate just on our business. The trust that One Point tax solution brings in is immense"
    }
];
