export const teamdata=[
    {
        "id":"01",
        "name":"V. Manojkumar",
        "designation":"Designated Partner",
        "about":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis convallis lorem. Mauris ac mattis leo. Etiam ullamcorper in magna at tincidunt.",
        "sociallinks":{
            "facebook":"",
            "twitter":"",
            "linkedin":""
        },
        "imgUrl":"assets/team/team1-manoj.jpg",
        "imgMeta":"V. Manojkumar's photo"
    },
    {
        "id":"02",
        "name":" C. Kuppuraj B.Com, CA (Final)",
        "designation":"Indirect Tax Specialist",
        "about":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis convallis lorem. Mauris ac mattis leo. Etiam ullamcorper in magna at tincidunt.",
        "sociallinks":{
            "facebook":"",
            "twitter":"",
            "linkedin":""
        },
        "imgUrl":"assets/team/team2-kuppuraj.png",
        "imgMeta":"C. Kuppurah' photo"
    },
    {
        "id":"03",
        "name":" CA V V S Narayanan",
        "designation":" Chartered Accountant",
        "about":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis convallis lorem. Mauris ac mattis leo. Etiam ullamcorper in magna at tincidunt.",
        "sociallinks":{
            "facebook":"",
            "twitter":"",
            "linkedin":""
        },
        "imgUrl":"assets/team/team3-narayana.jpg",
        "imgMeta":"CA VVS Narayana's photo"
    },
    {
        "id":"04",
        "name":"CA Thota Narasimha Rao",
        "designation":"Chartered Accountant",
        "about":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis convallis lorem. Mauris ac mattis leo. Etiam ullamcorper in magna at tincidunt.",
        "sociallinks":{
            "facebook":"",
            "twitter":"",
            "linkedin":""
        },
        "imgUrl":"assets/team/team4-thota.jpeg",
        "imgMeta":"Thota Narasimha Rao's photo"
    },
    {
        "id":"05",
        "name":"Mohankumar .S B.Com, CA and CS (final)",
        "designation":" Direct Tax Specialist",
        "about":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis convallis lorem. Mauris ac mattis leo. Etiam ullamcorper in magna at tincidunt.",
        "sociallinks":{
            "facebook":"",
            "twitter":"",
            "linkedin":""
        },
        "imgUrl":"assets/team/team5-mohankumar.jpg",
        "imgMeta":"Mohankumar S's photo"
    },

]