import React from 'react'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import './Greetingpage.css'
function Greetingpage() {
    return (
        <>
            <Header />
            <div className="container-fluid  py-5" style={{ background: `url(${process.env.PUBLIC_URL + '/assets/backgrounds/bgone.png'})` }}>
                <div className="container">
                    <div className="card shadow p-5">
                        <div className="card-body cardsize">
                            <div class="d-flex justify-content-center align-contents-center align-items-center  ">
                                <div class=" "><h2 className="cardsize__text">Thank you for choosing us</h2></div>
                            </div>
                            <div class="d-flex justify-content-center align-contents-center align-items-center  ">
                                <div class=" "><img  src="assets/logo.jpg" alt="Thank You pic" /></div>
                            </div>
                            <div class="d-flex justify-content-center align-contents-center align-items-center  ">
                                <div class=" "><a className="btn btn-primary" href="/">Back to Home</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Greetingpage
