import { getAllUpdates, getUpdate } from '../../data/updates';

export const getUpdates = async () => {
    const updates = await getAllUpdates();
    return updates;
};

export const getFullUpdate = async (updateKey) => {
    const fullUpdate = await getUpdate(updateKey);
    return fullUpdate;
};

export default getUpdates;
