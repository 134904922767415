import React, {useEffect} from 'react';

import Headerinfo from '../../components/headerinfo/Headerinfo';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Scrolltotop from '../../components/scrolltotop/Scrolltotop';
import Appointment from '../../container/appointment/Appointment';
import './Servicepage.css';


function Servicepage() {

    useEffect(() => {

        var listOfServices = [
            "nav-taxservice-tab", 
            "nav-accounting-tab",
            "nav-registration-tab",
            "nav-secretarial-tab",
            "nav-advisory-tab",
            "nav-other-tab"
        ];

        let url = window.location.href;
        let idx = url.indexOf("#");
        url = url.slice(idx + 1);
        
        let finalURL = listOfServices[0];
        if (listOfServices.includes(url)){
            finalURL = url;
        }
        document.getElementById(finalURL).click();
    }, []);
    

    return (
        <>
            <Headerinfo />
            <Header />
            <div className="container-fluid  py-5" style={{ background: `url(${process.env.PUBLIC_URL + '/assets/backgrounds/bgone.png'})` }}>

                <div className="container my-3 p-md-5 p-xs-1 shadow h-100 algintextfont " style={{ backgroundColor: 'white' }}>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a className="nav-link active" id="nav-taxservice-tab" data-toggle="tab" href="#nav-taxservice" role="tab" aria-controls="nav-taxservice" aria-selected="true">Tax Services</a>
                            <a className="nav-link" id="nav-accounting-tab" data-toggle="tab" href="#nav-accounting" role="tab" aria-controls="nav-accounting" aria-selected="false">Accounting Services</a>
                            <a className="nav-link" id="nav-registration-tab" data-toggle="tab" href="#nav-registration" role="tab" aria-controls="nav-registration" aria-selected="false">Registration</a>
                            <a className="nav-link" id="nav-secretarial-tab" data-toggle="tab" href="#nav-secretarial" role="tab" aria-controls="nav-secretarial" aria-selected="false">Secretarial Services</a>
                            <a className="nav-link" id="nav-advisory-tab" data-toggle="tab" href="#nav-advisory" role="tab" aria-controls="nav-advisory" aria-selected="false">Advisory Services</a>
                            <a className="nav-link" id="nav-other-tab" data-toggle="tab" href="#nav-other" role="tab" aria-controls="nav-other" aria-selected="false">Other Services</a>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-taxservice" role="tabpanel" aria-labelledby="nav-taxservice-tab">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 my-5">
                                        <ul>
                                            <li>Tax Preparation</li>
                                            <li>Tax Planning</li>
                                            <li>Tax on Non - Resident</li>
                                            <li>Transfer Pricing</li>
                                            <li>GST Filing</li>
                                            <li>ESI Filing</li>
                                            <li>PF Filing</li>
                                            <li>Litigation Support</li>
                                            <li>Internal Audit</li>
                                            <li>Stock Audit</li>
                                            <li>TDS Filing</li>
                                            <li>GST Audit</li>
                                        </ul>
                                    </div>
                                    <div className="col-6 my-5 col-sm-6 col-xs-12">
                                        <img alt="Tax Service" src="assets/services/01.jpg" className="img-responsive"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-accounting" role="tabpanel" aria-labelledby="nav-accounting-tab">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 my-5">
                                        <ul>
                                            <li>Book Keeping</li>
                                            <li>Project Report</li>
                                            <li>MIS Report</li>
                                            <li>Financial Forecasts 	&amp; Projections</li>
                                            <li>Cash Flows and Analysis</li>
                                            <li>Financial Statements</li>
                                            <li>Account receivable</li>
                                            <li>Account payable</li>
                                            <li>Forensic accounting</li>
                                            <li>Fiduciary Accounting</li>
                                        </ul>
                                    </div>
                                    <div className="col-6 my-5 col-xs-12">
                                        <img alt="Accounting Services" src="assets/services/02.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-registration" role="tabpanel" aria-labelledby="nav-registration-tab">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 my-5">
                                        <ul>
                                            <li>Goods and Service Tax (GST)</li>
                                            <li>Employee State Insurance (ESI)</li>
                                            <li>The Employees Provident Funds And Miscellaneous Provision's (EPF)</li>
                                            <li>Company Registration</li>
                                            <li>LLP Registration</li>
                                            <li>Partnership Registration</li>
                                            <li>Trust Registration</li>
                                            <li>IE Code</li>
                                            <li>PAN</li>
                                            <li>TAN</li>
                                            <li>Shop and Establishment</li>
                                            <li>Professional Tax</li>
                                            <li>Trade Mark</li>
                                            <li>FSSAI</li>
                                            <li>MSME Registration</li>
                                        </ul>
                                    </div>
                                    <div className="col-6 my-5 col-sm-6 col-xs-12">
                                        <img alt="Registration Services" src="assets/services/03.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-secretarial" role="tabpanel" aria-labelledby="nav-secretarial-tab">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 my-5">
                                        <ul>
                                            <li>Assistance in holding &amp; conducting Board Meeting, Annual General Meeting, and Extra-Ordinary General Meeting</li>
                                            <li>Maintenance of Minutes Book, Statutory Registers, and all other prescribed secretarial records under the Companies Act, 2013</li>
                                            <li>Filing of Annual Return &amp; Financial Statements with Registrar of Companies (ROC)</li>
                                            <li>Advisory on Corporate Laws &amp; other Allied Laws/Acts</li>
                                            <li>Advisory Services on the constitution of Board of Directors, Independent Directors, women Directors, small shareholders Directors, Audit Committee, Remuneration and Grievance Committee.</li>
                                            <li>Complete support and advisory services for implementation of Corporate Social Responsibility in compliance with the provisions of the Companies Act 2013</li>
                                            <li>Consolidation/sub-division of shares, share transfer and transmission, issue of shares certificates, dematerialization of shares, forfeiture of shares</li>
                                            <li>Compliances Relating to Limited Liability Partnership / Company i.e. Pvt. Ltd, Public Ltd, One Person Company (OPC) and Section 8 Company</li>
                                            <li>Ensuring board of director’s compliance as per Companies Act, 2013- ensuring minimum number of board meetings, confirmation of requisite quorum of board meeting, preparation and sending notice and agenda of board meeting, preparation of board resolutions as per Secretarial Standard</li>
                                            <li>Obtaining Director Identification Number (DIN)</li>
                                            <li>Increase and re-classification of Authorised Share Capital</li>
                                            <li>Compliances relating to Managerial Remuneration</li>
                                            <li>Compliances relating to Change of Name, Change of Objects, change of status from Public Ltd to Private Ltd and vice a versa</li>
                                            <li>Conversion of a Partnership Firm/ Limited Liability Partnerships (LLP) into a Company</li>
                                            <li>Change of Registered office</li>
                                            <li>Liaisoning with the office of Registrar of Companies, Regional Director and Company Law Board for obtaining various regulatory approvals</li>
                                            <li>Procedures and compliances related to making inter-company loans, investments, guarantees, and providing of securities.</li>
                                            <li>FCGPR Compliance</li>
                                            <li>Allotment of shares in accordance with the provisions of Companies Act, 2013</li>
                                        </ul>
                                    </div>
                                    <div className="col-6 my-5 col-sm-6 col-xs-12">
                                        <img alt="Tax Service" src="assets/services/04.jpg"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-advisory" role="tabpanel" aria-labelledby="nav-advisory-tab">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 my-5">
                                        <ul>
                                            <li>Accounting Software Selection &amp; Implementation</li>
                                            <li>Investment Review</li>
                                            <li>Management Advisory Services</li>
                                            <li>Hiring or supervising accounting staff</li>
                                            <li>Inventory setup, reporting and analysis</li>
                                            <li>Estate Planning</li>
                                            <li>Business Consultancy</li>
                                            <li>Payroll processing</li>
                                            <li>Training Accounting Software</li>
                                            <li>Legal services</li>
                                            <li>Preparation of Deeds</li>
                                        </ul>
                                    </div>
                                    <div className="col-6 col-sm-6 my-5 col-xs-12">
                                        <img alt="Tax Service" src="assets/services/05.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-other" role="tabpanel" aria-labelledby="nav-other-tab">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 my-5">
                                        <ul>
                                            <li>Digital Signature Certificate (DSC)</li>
                                            <li>Business Networking</li>
                                            <li>Due Diligence</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6 col-6 my-5 col-xs-12">
                                        <img alt="Tax Service" src="assets/services/06.jpg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="book" className="container-fluid appoinment__container">
                <Appointment />
            </div>
            <Footer />
            <Scrolltotop />
        </>
    )
}

export default Servicepage;
