import React, { useCallback, useState } from 'react';
import Form from '../../components/forms/Form';
import Testimonials from '../../components/testimonials/Testimonials';

import './Appointment.css';

function Appointment() {

    const [headerchange, setHeaderchange] = useState(false);

    const callback = useCallback((checkboxval) => {
        setHeaderchange(checkboxval);
    }, []);
    return (
        <div className="row row-flex">
            <div className="col-1"></div>
            <div className="col-lg-5 col-md-5 col-12 col-xs-12 py-5 my-5">
                <div className="card shadow content p-5">
                    <h2 className="color-1">{headerchange ? 'Book Appointment' : 'Contact us'}</h2>
                    <p className="color-3">Please feel welcome to contact our friendly reception staff with any general or mdeical enquiry</p>
                    <Form parentCallback={callback} />
                </div>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 py-5 my-5">
                <div className="card shadow content p-5">
                    <h2 className="color-1">Testimonials</h2>
                    
                    <div className="py-5">
                        <Testimonials />
                    </div>
                </div>
            </div>
            <div className="col-1"></div>
        </div>
    )
}

export default Appointment;
