import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <>
            <footer id="footer" className="footer-section">
                <div className="footer__container container-fluid ">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <h6 className="footer__heading">OPENING HOURS</h6>
                                <table className="table text-white my-2">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Monday - Friday </th>
                                            <td>10:00 AM - 6:00 PM</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Saturday</th>
                                            <td>10:00 AM - 06:00 PM</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Sunday</th>
                                            <td>10:00 AM - 06:00 PM</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Emergency</th>
                                            <td>24Hr / 7Days</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-xs-6 col-md-3">
                                <h6 className="footer__heading">LINKS</h6>
                                <ul className="footer__links">
                                    <li><a href="/#aboutus">About us</a></li>
                                    <li><a href="/#service">Our Service</a></li>
                                    <li><a href="/#team">Our Team</a></li>
                                    <li><a href="/#footer">Contact us</a></li>
                                </ul>
                            </div>
                            <div className="col-xs-6 col-md-3">
                                <div className="card ">
                                    <div className="card-body">
                                        <h6 className="card-title">Quick Contacts</h6>
                                        {/* <p className="card-subtitle mt-3 text-muted">Contact based Caption</p> */}
                                        <p> (+91) 9940 112874</p>
                                        <p>
                                            Plot No.3 & 4, New No.48, Fourtts Avenue, Annai Indhira Nagar, Thoraipakkam, Chennai - 600097
                                        </p>
                                        <p><a className="btn btn-primary" href="https://goo.gl/maps/TJ7vBJ75vQoSx3pw8" rel="noreferrer" target="_blank">Get Directions</a></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <p className="color-2 text-center">Copyright &#169; <bold>OPTSLLP</bold>. All Rights Reserved. Made with ❤️ <a href="http://indiespirit.in/" rel="noreferrer" target="_blank"> by Indiespirit.in</a></p>
                <hr className="footer-line" />
            </footer>
        </>
    )
}

export default Footer;
