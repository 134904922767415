export const slider = [
    {
        "id":"001",
        "img":'slider/001.jpg',
        "caption":"Your trusted business advisor to increase your profits!"
    },
    {
        "id":"002",
        "img":'slider/002.jpg',
        "caption":"Our proven industry experience to help you achieve your goals."
    },
    {
        "id":"003",
        "img":'slider/003.jpg',
        "caption":"Quality services in shorter period at affordable cost !"
    }
    ,{
        "id":"004",
        "img":'slider/004.jpg',
        "caption":"We make your business Easier !"
    }
    ,{
        "id":"005",
        "img":'slider/005.jpg',
        "caption":"Services at scale and ease through digital empowerment !"
    }

]