import React from 'react';
import './Header.css';
import { ImParagraphRight } from "react-icons/im";
import { BiCalendar } from "react-icons/bi";
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

export default function Header() {
    return (
        <>
            <Container className="header_container">
                <Navbar expand="sm" variant="light" bg="transparent">
                    <Navbar.Brand href="/"><img className="navbar__image" src="/assets/logo.png" alt="One Point Logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav >
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/#aboutus">About us</Nav.Link>
                            <Nav.Link href="/#footer">Contact us</Nav.Link>
                            <NavDropdown title={<><ImParagraphRight /> Services</>} id="navbarScrollingDropdown">
                                <NavDropdown.Item href="/service#nav-taxservice-tab">Tax Services</NavDropdown.Item>
                                <NavDropdown.Item href="/service#nav-accounting-tab">Accounting Service</NavDropdown.Item>
                                <NavDropdown.Item href="/service#nav-registration-tab">Registration</NavDropdown.Item>
                                <NavDropdown.Item href="/service#nav-secretarial-tab">Secretarial  Services</NavDropdown.Item>
                                <NavDropdown.Item href="/service#nav-advisory-tab">Advisory Services</NavDropdown.Item>
                                <NavDropdown.Item href="/service#nav-other-tab">Other Services</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link className="btn btn-primary text-white" href="#book"><span><BiCalendar /> Get Quote</span></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>

                </Navbar>
            </Container>
        </>
    )
}
