import AWS from 'aws-sdk';

const AWS_REGION = 'ap-south-1';
const AWS_ACCESS_KEY_ID = 'AKIA22IE2J2VTYRQKKCL';
const AWS_SECRET_KEY_ID = 'sHVW475zaaGsK845QcbrhuYe2NsWbvr7HC9OZQpp';
const updatesBucket = 'optsllp-site-updates';
const updatesFolder = 'updates/';
const assetsFolder = 'assets/';
const updatesFileExtension = '.json';

AWS.config.update(
    {
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_KEY_ID,
        region: AWS_REGION
    }
);

const listBucketObjects = async (s3Client, bucketParams, allObjectKeys) => {
    const data = await s3Client.listObjectsV2(bucketParams).promise();
    const contents = data.Contents;
    contents.forEach((content) => allObjectKeys.push(content.Key));
    if (data.IsTruncated) {
        const s3ParamsUpdated = bucketParams;
        s3ParamsUpdated.ContinuationToken = data.NextContinuationToken;
        await listBucketObjects(s3Client, s3ParamsUpdated, allObjectKeys);
    }
};

export const getAllUpdates = async () => {
    const s3Client = new AWS.S3();
    const bucketObjects = [];
    const updates = [];
    const bucketParams = {
        Bucket : updatesBucket,
        Prefix: updatesFolder,
        Delimiter: '/',
    };

    await listBucketObjects(s3Client, bucketParams, bucketObjects);
    bucketObjects.forEach(object => {
        if(object !== updatesFolder) {
            const fileName = object.split('/')[1];
            const updateName = fileName.split(updatesFileExtension)[0];
            updates.push(updateName);
        }
    });
    return updates;
};

export const getUpdate = async (updateKey) => {
    const s3Client = new AWS.S3();
    const s3Params = {
      Bucket: updatesBucket,
      Key: `${updatesFolder}${updateKey}${updatesFileExtension}`,
    };

    const object = await s3Client.getObject(s3Params).promise();  
    const update = new TextDecoder().decode(object.Body);
    return update;
};

export const getImage = async (imageName) => {
    const s3Client = new AWS.S3();
    const s3Params = {
      Bucket: updatesBucket,
      Key: `${assetsFolder}${imageName}`,
    };
    const image = await s3Client.getObject(s3Params).promise();  
    return image;
}