import React from 'react';
import { MdPhone } from "react-icons/md";
import { ImLocation } from "react-icons/im";
import { BiTimeFive } from "react-icons/bi";
import Sociallinks from '../sociallinks/Sociallinks';


function Headerinfo() {
    return (
        <header className="header__main">
            <ul className="d-flex justify-content-around header__container">
                <li>We make your business Easier !</li>
                <li><span><MdPhone className="header__iconColor" /></span> (+91) 9940 112874 </li>
                <li><span><ImLocation className="header__iconColor" /></span>Plot No.3 & 4, New No.48, Fourtts Avenue, Annai Indhira Nagar, Thoraipakkam, Chennai - 600097</li>
                <li><BiTimeFive className="header__iconColor" />10:00 AM - 06:00 PM</li>
                <li>
                    <ul className="d-flex header__social">
                        {/* <li><Sociallinks size="22" color="#21CDC0" link="https://www.linkedin.com/in/one-point-tax-solutions-llp-a3408621a" linktype="linkedin"/></li> */}
                        <li><Sociallinks size="22" color="#21CDC0" link="https://www.facebook.com/OPTSLLP/" linktype="facebook"/></li>
                        <li><Sociallinks size="22" color="#21CDC0" link="https://www.instagram.com/optsllp/" linktype="instagram"/></li>
                        <li><Sociallinks size="22" color="#21CDC0" link=" https://wa.me/message/BRFREKI4UMVWH1" linktype="whatsapp"/></li>
                    </ul>

                </li>
            </ul>
        </header>
    )
}

export default Headerinfo
