import React from 'react'
import './Homepage.css';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Cards from '../../components/cards/Cards';
import Slider from '../../components/slider/Slider';
import HeaderBox from '../../container/headerbox/HeaderBox';
import Appointment from '../../container/appointment/Appointment';
import Team from '../../components/team/Team';
import Headerinfo from '../../components/headerinfo/Headerinfo';
import Scrolltotop from '../../components/scrolltotop/Scrolltotop';
import { service } from '../../data/service';

function Homepage() {
    
    return (
        <div>
            <Headerinfo />
            <Scrolltotop/>
            <Header />
            <section  className="slider pb-3" style={{ background: `url(${process.env.PUBLIC_URL + '/assets/backgrounds/bgone.png'})` }} >
                <Slider className="slider__slider" />
                <HeaderBox className="slider__header" />
                <div className="container my-5" id="service">
                    <h2 className="text-center my-2 mb-4">OUR SERVICES</h2>
                    <div className="row">
                        <Cards title="Tax Services" url="/service#nav-taxservice-tab" imgurl={service[0]} desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis convallis lorem. Mauris ac mattis leo. Etiam ullamcorper in magna at tincidunt." />
                        <Cards title="Accounting Service" url="/service#nav-accounting-tab" imgurl={service[1]} desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis convallis lorem. Mauris ac mattis leo. Etiam ullamcorper in magna at tincidunt." />
                        <Cards title="Registration" url="/service#nav-registration-tab" imgurl={service[2]} desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis convallis lorem. Mauris ac mattis leo. Etiam ullamcorper in magna at tincidunt." />
                    </div>
                    <div className="row my-2">
                        <Cards title="Secretarial Services" url="/service#nav-secretarial-tab" imgurl={service[3]} desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis convallis lorem. Mauris ac mattis leo. Etiam ullamcorper in magna at tincidunt." />
                        <Cards title="Advisory Services" url="/service#nav-advisory-tab" imgurl={service[4]} desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis convallis lorem. Mauris ac mattis leo. Etiam ullamcorper in magna at tincidunt." />
                        <Cards title="Other Services" url="/service#nav-other-tab" imgurl={service[5]} desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis convallis lorem. Mauris ac mattis leo. Etiam ullamcorper in magna at tincidunt." />
                    </div>

                </div>
            </section>
            <section>
                <div id="aboutus" className="container ">
                    <h2 className="text-center my-5">ABOUT US</h2>
                    <div className="row my-5">
                        <div className="my-5 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="embed-responsive embed-responsive-16by9">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/6WquGp062kY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="py-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="row p-3">
                                    <h2 className="color-1 ">We make your business Easier!</h2>
                                    <p className="my-4 ">One Point Tax Solutions LLP is a Financial and Management Consulting Firm focused on serving the comprehensive needs of businesses in the wide range of the business cycle with a core staff of well experienced professionals. OPTS will be able to offer a more balanced quality service to the customers.</p>
                            </div>
                            {/* <div className="row py-2">
                                <ul className=" d-flex no-list-style list-padding">
                                    <li><p className="dummy-btn"><BsHeartFill /> Follow Us</p></li>
                                    <li><Sociallinks size="24" color="#21CDC0" link="https://www.facebook.com/OPTSLLP/" linktype="facebook" /></li>
                                    <li><Sociallinks size="24" color="#21CDC0" link="https://www.linkedin.com/in/one-point-tax-solutions-llp-84bb5277" linktype="linkedin" /></li>
                                    <li><Sociallinks size="22" color="#21CDC0" link="https://youtube.com/channel/UCE9GiP9uOhUXWHZPHBssoxg" linktype="youtube" /></li>
                                    <li><p className="dummy-btn">Read More</p></li>
                                </ul>
                            </div> */}
                            {/* <h4 class="color-1 text-center">Clients</h4>
                            <div className="d-flex center-div">
                                <img alt="client" className="about-client" src="assets/clients/clients.png"/>
                                <img alt="client" className="about-client" src="assets/clients/clients.png"/>
                                <img alt="client" className="about-client" src="assets/clients/clients.png"/>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            <div id="book" className="container-fluid appoinment__container">
                <Appointment />
            </div>
            {/* <div className=" my-5 container">
                <div className="my-5 row">
                    <div className="my-lg-5 my-md-5 my-3 col-md-6 col-lg-6 col-sm-6 col-xs-12">
                        <h2 className="my-lg-5 my-md-5 my-3 justify-content-center">Testimonials</h2>
                    </div>
                    <div className="my-lg-5 my-md-5 my-3 col-md-6 col-lg-6 col-sm-6 col-xs-12">
                        <Testimonials />
                    </div>
                </div>
            </div> */}
            <div className="container my-4" id="team">
                <h2 className="text-center my-4">MEET OUR TEAM</h2>
                <Team />
            </div>
            <Footer />

           
        </div>
    )
}

export default Homepage;
