import React from 'react'
import { teamdata } from '../../data/teamdata';
import './Team.css';

function Team() {
    return (
        <>
        <div className="row">
            <div className="col-md-4 col-12 h-100" key={teamdata[0].id}>
                <div className="card-body">
                    <img className="r w-100 h-100 rounded-circle" alt={teamdata[0].imgMeta} src={teamdata[0].imgUrl} />
                    <h5 className="color-1 py-2 text-center">{teamdata[0].name}</h5>
                    <h6 className="color-2 text-center"> {teamdata[0].designation}</h6>
                </div>
            </div>
            <div className="col-md-4 col-12 h-100" key={teamdata[1].id}>
                <div className="card-body">
                    <img className="r w-100 h-100 rounded-circle" alt={teamdata[1].imgMeta} src={teamdata[1].imgUrl} />
                    <h5 className="color-1 py-2 text-center">{teamdata[1].name}</h5>
                    <h6 className="color-2 text-center"> {teamdata[1].designation}</h6>
                </div>
            </div>
            <div className="col-md-4 col-12 h-100" key={teamdata[4].id}>
                <div className="card-body">
                    <img className="r w-100 h-100 rounded-circle" alt={teamdata[4].imgMeta} src={teamdata[4].imgUrl} />
                    <h5 className="color-1 py-2 text-center">{teamdata[4].name}</h5>
                    <h6 className="color-2 text-center"> {teamdata[4].designation}</h6>
                </div>
            </div>
            
            <div className="col-md-1"></div>
        </div>
        <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-4 col-12 h-100" key={teamdata[3].id}>
                <div className="card-body">
                    <img className="r w-100 h-100 rounded-circle" alt={teamdata[3].imgMeta} src={teamdata[3].imgUrl} />
                    <h5 className="color-1 py-2 text-center">{teamdata[3].name}</h5>
                    <h6 className="color-2 text-center"> {teamdata[3].designation}</h6>
                </div>
            </div>
            <div className="col-md-4 col-12 h-100" key={teamdata[2].id}>
                <div className="card-body">
                    <img className="r w-100 h-100 rounded-circle" alt={teamdata[2].imgMeta} src={teamdata[2].imgUrl} />
                    <h5 className="color-1 py-2 text-center">{teamdata[2].name}</h5>
                    <h6 className="color-2 text-center"> {teamdata[2].designation}</h6>
                </div>
            </div>
            <div className="col-md-2"></div>
        </div>
        </>
    )
}

export default Team;
