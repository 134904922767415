import React, { useState } from 'react';
import { FaArrowAltCircleUp } from "react-icons/fa";
import './scrolltotop.css';

function Scrolltotop() {
    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <>
            <button className="scrollbutton">
                <FaArrowAltCircleUp onClick={scrollToTop}
                    style={{ display: visible ? 'inline' : 'none' }} className="scrollbutton__btn" />
            </button>

        </>
    )
}

export default Scrolltotop
