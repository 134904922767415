import React, { useState } from 'react';
import './Form.css';
function Form({ parentCallback }) {
    const [showcheckbox, setShowcheckbox] = useState(false);
    const [submit, setSubmit] = useState(false);
    const checkboxHandler = (e) => {
        setShowcheckbox(!showcheckbox);
        parentCallback(!showcheckbox);
    }
    const redirectionHandler = () => {
        if (submit) {
            window.location = "/greeting";
        }
    }

    const formSubmithandler=()=>{
        setSubmit(true);
    }

    return (
        <>
            <iframe title="Hidden Form" onLoad={redirectionHandler()} name="hidden_iframe" id="hidden_iframe" style={{ display: 'none' }}></iframe>
            <form className="form-group"  action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSc9GjLvxBawV3cWPkTx6MUlNtX4vXXY-brWEFUpwls5kXNqtg/formResponse" method="post" target="hidden_iframe">
                <div className="form-row">
                    <div className="col-md-12 my-4 px-0" >
                        <input className="form-control forms-style" type="text" name="entry.1387318072" placeholder="Name" required />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-8 my-4 px-0">
                        <input className="form-control forms-style" type="email" name="entry.1450935971" placeholder="Email" required />
                    </div>
                    <div className="from-group col-md-4 col-xs-12 my-4 pr-0">
                        <input className="form-control forms-style" type="text" name="entry.1826812445" placeholder="Phone Number" required />
                    </div>

                </div>

                <div className="form-row">
                    <div className="col-md-12 my-4 px-0">
                        <select id="servicelist" className="form-control forms-style" name="entry.1865036995" required>
                            <option value="Tax Services">Tax Services</option>
                            <option value="Accounting Services">Accounting Services</option>
                            <option value="Business Registration">Business Registration</option>
                            <option value="Secretarial Services">Secretarial Services</option>
                            <option value="Advisory Services">Advisory Services</option>
                            <option value="Other Services">Other Services</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="from-group col-md-12 col-xs-12 my-4 px-0" >
                        <input type="text" className="form-control forms-style" name="entry.775586617" placeholder="Other Queries" />

                    </div>
                </div>
                <div className="form-row">
                    <div className="from-group col-md-12 col-xs-12 my-2">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="checkbox" value={showcheckbox} checked={showcheckbox} placeholder="check" onChange={(e) => checkboxHandler(e)} />
                            <label className="form-check-label" htmlFor="checkbox">I would like to fix Date and Time </label>
                        </div>
                    </div>
                </div>

                {showcheckbox === true ? <div className="form-row">
                    <div className="from-group col-md-6 col-xs-12 my-4">
                        <input className="form-control forms-style" type="date" name="entry.429118407" placeholder="Date" />
                    </div>
                    <div className="from-group col-md-6 col-xs-12 my-4">
                        <input className="form-control forms-style" type="time" name="entry.501278872" placeholder="Time" />
                    </div>
                </div> : <></>}

                <div className="form-row">
                    <div className="col-md-12 my-4">
                        <input type="submit" value={showcheckbox ? 'Book' : 'Submit'} onClick={()=>formSubmithandler()} className="form-control btn btn-secondary forms-style" />
                    </div>
                </div>
            </form>
        </>
    )
}

export default Form;
