import React, { useState, useEffect } from 'react'
import Headerinfo from '../../components/headerinfo/Headerinfo';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Scrolltotop from '../../components/scrolltotop/Scrolltotop';
import Appointment from '../../container/appointment/Appointment';
import './Blogpage.css';
import { getFullUpdate, getUpdates } from '../../components/updates/updates';
import { useParams } from 'react-router-dom';

function Blogpage(props) {
    let { titles } = useParams();
    const [content, setContent] = useState("");
    const [imageurl, setImageurl] = useState("");
    const [date, setDate] = useState("");
    const [title, settitle] = useState("");
    const [isloaded, setIsloaded] = useState(false);
    const [updates, setUpdates] = useState([]);

    useEffect(() => {
        const fetchObject = () => {
            getFullUpdate(titles).then((data) => {
                const obj = JSON.parse(data);
                setContent(obj.content);
                setImageurl(obj.images[0]);
                setDate(obj.date);
                settitle(obj.title);
                setIsloaded(true);
            })
        }
        fetchObject();
    }, [titles]);
    useEffect(() => {
        getUpdates().then(async (updatedata) => {
            const updateobj = [];
            for (var i = 0; i < updatedata.length; i++) {
                // eslint-disable-next-line no-loop-func
                await getFullUpdate(updatedata[i]).then((data) => {
                    const obj = JSON.parse(data);
                    obj.url = updatedata[i];
                    updateobj.push(obj);
                })
            }
            setUpdates(updateobj);
        });
    }, [isloaded]);
    const updateImageBaseURL = 'https://optsllp-site-updates.s3.ap-south-1.amazonaws.com/assets/';
    return (
        <div>
            <Headerinfo />
            <Header />
            <div>
                <img src={"/assets/backgrounds/bgtwo.jpg"} alt="Banner" className="img-fluid bg-img" />
            </div>
            <Scrolltotop />
            <div className="container my-5 py-5">
                <div>
                    <p className="py-2">Home ☐ Blog ☐ {title}</p>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <img src={`${updateImageBaseURL}${imageurl}`} alt="Source" className="img-fluid" />
                        <p className="color-3 py-2">{date}</p>
                        <h2 className="color-1">{title}</h2>

                        <p className="color-3" dangerouslySetInnerHTML={{ __html: content }}>
                        </p>
                    </div>
                    <div className="col-md-4">
                        <div className="d-flex flex-column div-container">
                            <div className="card card-background">
                                <h3 className="color-1 px-4 pt-4">Recent Updates</h3>

                                {updates.map((item, index) =>
                                    <div key={index} className="card-body ">
                                        <div className="row my-3">
                                            <div className="col-md-6">
                                                <div className="card ">
                                                    <img src={`${updateImageBaseURL}${item.images[0]}`} alt="Source" className="img-fluid bg-img" />
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <p className="color-1">{item.date}</p>
                                                <h4 className="text-algin-start">
                                                    <a href={`/blog/${item.url}`} >
                                                        {item.title}
                                                    </a>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="book" className="container-fluid appoinment__container">
                <Appointment />
            </div>
            <Footer />
        </div>
    )
}

export default Blogpage;
