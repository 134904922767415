import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { testimonialsdata } from '../../data/testimonial';
// UPDATE testimonialsdata for further Data Updation 

function Testimonials() {
    return (
        <>
            <div >
                <Carousel indicators={true} controls={true}>
                    {testimonialsdata.map((item) =>
                        <Carousel.Item key={item.id} >
                            <div className="pt-4 mt-4">
                                <p className="testimonial-font" ><sup className="color-1 "><ImQuotesLeft /></sup> {item.testimonial} <sup><ImQuotesRight className="color-1" /></sup> </p>
                                <div className="row justify-content-center my-5">
                                    <div className="col-lg-6 col-md-6 col-xs-12 py-5 order-xs-2">
                                        <i className="testimonial-font ">{item.name}</i><br/>
                                        <p className="testimonial-font "> <b>{'  '}{item.company}</b></p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-xs-12 order-xs-1">
                                        <img
                                            className="testimonial-uniform-image rounded-circle "
                                            alt="First slide"
                                            src={item.imageUrl}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                    )}
                </Carousel>
            </div>


        </>
    )
}

export default Testimonials
