import React, { useState, useEffect } from 'react'
import Headerinfo from '../../components/headerinfo/Headerinfo';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Scrolltotop from '../../components/scrolltotop/Scrolltotop';
import Appointment from '../../container/appointment/Appointment';
import './Updatepage.css';
import { getUpdates, getFullUpdate } from '../../components/updates/updates';
import {Link} from 'react-router-dom';

function Updatepage(props) {
    const [updates, setUpdates] = useState([]);

    useEffect(() => {
        getUpdates().then(async (updatedata) => {
            const updateobj = [];
            for (var i = 0; i < updatedata.length; i++) {
                await getFullUpdate(updatedata[i]).then((data) => {
                    const obj = JSON.parse(data);
                    updateobj.push(obj);
                })
            }
            setUpdates(updateobj);
        });
    }, []);
    const updateImageBaseURL = 'https://optsllp-site-updates.s3.ap-south-1.amazonaws.com/assets/';
    return (
        <div>
            <Headerinfo />
            <Header />
            <div>
                <img src={"assets/backgrounds/bgtwo.jpg"} alt="Tag" className="img-fluid bg-img" />
            </div>
            <Scrolltotop />
            <div className="container my-5 py-5">
                <div>
                    <p className="py-2">Home ☐ Update ☐ Updates</p>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <img src={""} alt="Source" className="img-fluid bg-img" />
                        <p className="color-3 py-2">{}</p>
                        <h2 className="color-1">{}</h2>

                        <p className="color-3" >
                        </p>
                    </div>
                    <div className="col-md-4">
                        <div className="d-flex flex-column div-container">
                            <div className="card card-background">
                                <h3 className="color-1 px-4 pt-4">Recent Updates</h3>

                                {updates.map((item, index) =>
                                    <div key={index} className="card-body ">
                                        <div className="row my-3">
                                            <div className="col-md-6">
                                                <div className="card ">
                                                    <img src={`${updateImageBaseURL}${item.images[0]}`} alt="Source" className="img-fluid bg-img" />
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <p className="color-1">{item.date}</p>
                                                <h4 className="text-algin-start">
                                                <Link
                                                    to={{
                                                        pathname: '/updates',
                                                        serviceProps: {
                                                            title: item.title,
                                                            content: item.content,
                                                            image: item.images[0],
                                                            date: item.date
                                                        }
                                                    }} >
                                                    {item.title}
                                                </Link>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                )}


                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div id="book" className="container-fluid appoinment__container">
                <Appointment />
            </div>
            <Footer />
        </div>
    )
}

export default Updatepage
