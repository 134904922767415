
import React, { useEffect } from 'react';
import './HeaderBox.css';
import { getUpdates, getFullUpdate } from '../../components/updates/updates';
import { useState } from 'react';
// import Marquee from "react-easy-marquee";


function HeaderBox() {
    const [updates, setUpdates] = useState([]);
    const [pause,setPause]=useState(false);
    
    useEffect(() => {
        getUpdates().then(async (updatedata) => {
            const updateobj = [];
            for (var i = 0; i < updatedata.length; i++) {
                // eslint-disable-next-line no-loop-func
                await getFullUpdate(updatedata[i]).then((data) => {
                    const obj = JSON.parse(data);
                    obj.url = updatedata[i];
                    updateobj.push(obj);
                })
            }
            setUpdates(updateobj);
        });

    }, []);
    useEffect(()=>{
        if(pause)
            document.getElementById('marquee').stop();
        else
            document.getElementById('marquee').start();
    },[pause])
    
    const pauseHandler=()=>{
        setPause(!pause);
    }

    return (
        <div className="container">
            <div className="row box_container">
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 box-1">
                    <div className="my-1">
                        <div className="d-flex justify-content-center ">
                            <img className="img-fluid headerbox__image " src="assets/intro/10year.png" alt="10 plus Experience" />
                        </div>
                        <h4 className="box1-text text-center">10+ years of industry experience</h4>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 box-2">
                    <div className="my-1">
                        <div className="d-flex justify-content-center ">
                            <img className="img-fluid headerbox__image" src="assets/intro/remainder.png" alt="10 plus Experience" />
                        </div>
                        <h4 className="box1-text text-center">Over 100+ Happy Clients</h4>
                    </div>

                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 box-3">
                    <div className="my-5">

                        <div className="d-flex flex-column box-3-inner ">
                            {
                                // eslint-disable-next-line
                                <marquee
                                id="marquee"
                                loop=""
                                direction="up"
                                className="box3-marquee"
                                scrollamount="3"
                                onMouseEnter={()=>pauseHandler()}
                                onMouseLeave={()=>pauseHandler()}
                                behavior="scroll"
                                >
                                {
                                    updates.map((item, index) =>
                                        <><a className="text-white box-3-links" href={`blog/${item.url}`}>{item.title}</a><br/><br/></>
                                    )
                                }
                            </marquee>}


                            <h4 className="box1-text text-center box-3-padding">Updates and Blogs</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderBox;
