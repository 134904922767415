import React from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import './Cards.css';

function Cards(props) {
    return (
        <>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 py-lg-3 py-md-3 py-sm-3 py-xs-3">
                <div className="card h-100 shadow ">
                    <div className="card-body border-bottom border-primary card_box">
                        <div className="d-flex flex-row-reverse">
                            <a href={props.url} ><button className="btn-outline-secondary btn-sm card__btn"><strong>Read More <span><BsArrowRightShort /></span></strong></button></a>
                        </div>
                        <div className="container ">
                            <img alt={props.title} src={props.imgurl} className="img-fluid justify-content-center" />
                            <h4 className="card__container__header text-center ">{props.title}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cards
