import './App.css';
import Homepage from './pages/homepage/Homepage';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Servicepage from './pages/servicepage/Servicepage';
import Greetingpage from './pages/greetingpage/Greetingpage';
import Updatepage from './pages/updatepage/Updatepage';
import Blogpage from './pages/blogpage/Blogpage';
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route path="/service" component={Servicepage} />
          <Route exact path="/greeting" component={Greetingpage} />
          <Route exact path="/updates" component={Updatepage} />
          <Route exact path="/blog/:titles" component={Blogpage} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
