import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { slider } from '../../data/slider';
import './Slider.css';


function Slider() {
    
    return (
        <Carousel indicators={false} className="slidercontainer">
            {slider.map((item) =>
                <Carousel.Item className="slideritem" key={item.id}>
                    <img
                        className="d-block w-100 slideritem"
                        src={item.img}
                        alt="First slide"
                    />
                    <Carousel.Caption  className="mb-lg-5 mb-xs-1">
                        <h1 className="text-center mb-lg-5 mb-md-5 mb-sm-2 mb-xs-1 slider__caption">{item.caption}</h1>
                    </Carousel.Caption>
                </Carousel.Item>
            )}
        </Carousel>
    )
}

export default Slider;
